<template>
  <BadgeAgrupation
    v-for=" badgeAgrupation in badgesAgrupation"
    :key="badgeAgrupation.promoId" 
    :dataAgrupation="badgeAgrupation"
    :price="price"
    @promotionIframe="setPromotionIframeAsVisible" />

  <ModalWithIframe
    v-if="showPromotionIframe"
    :iframeURL="iframeUrl"
    @closeModal="closeModal" />
</template>
<script>

import BadgeAgrupation from './BadgeAgrupation.vue';

  export default {
    components: { 
      BadgeAgrupation,
      ModalWithIframe: defineAsyncComponent(() => import('CommonComponents/Iframe/ModalWithIframe.vue')), 
    },
    props: {
      badgesAgrupation: { 
        type: Array, 
        required: true 
      },
      price: {
        type: Object,
        required: true,
        default: () => {},
      }
    },
    setup() {
      
      const iframeUrl = ref('');
      const showPromotionIframe = ref(false);

      function closeModal() {
        showPromotionIframe.value = false;
      }

      function setPromotionIframeAsVisible(iframeURL) {
        iframeUrl.value = iframeURL;
        showPromotionIframe.value = true;
      }

      return { 
        iframeUrl,
        showPromotionIframe,
        closeModal,
        setPromotionIframeAsVisible,
      };
    },
  };
</script>