<template>
  <div>
    <div
      v-if="selectedDate && !isTicketsProduct"
      class="product_preview-more-dates-band">
      {{ $t('assets.product_preview.date_band.lanzamiento') }} {{ selectedDate }}
    </div>
    <div
      v-else-if="selectedDate && isTicketsProduct"
      class="product_preview-badge date-band launching-date">
      {{ $t('assets.product_preview.event_scheduled.desde') }} {{ selectedDate }}
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'PreviewDates',
    props: {
      launchingDate: {
        type: String,
        required: false,
        default: '',
      },
      availabilityDate: {
        type: String,
        required: false,
        default: '',
      },
      isTicketsProduct: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const { t } = useI18n();

      const months = [
        t('month.january'),
        t('month.february'),
        t('month.march'),
        t('month.april'),
        t('month.may'),
        t('month.june'),
        t('month.july'),
        t('month.august'),
        t('month.september'),
        t('month.october'),
        t('month.november'),
        t('month.december'),
      ];
      const weekDays = [
        t('day.sunday'),
        t('day.monday'),
        t('day.tuesday'),
        t('day.wednesday'),
        t('day.thursday'),
        t('day.friday'),
        t('day.saturday'),
      ];

      const getReadableDate = (unformattedDate, isTicketsProduct, haveSkipYear) => {
        const date = unformattedDate ? new Date(unformattedDate) : null;
        const skipYear = haveSkipYear || false;

        if (!date) return;
        if (Object.prototype.toString.call(date) === '[object Date]' && isNaN(date.getTime())) return;

        let formatedDate = '';
        const weekDay = date.getDay();
        const dd = date.getDate();
        const mm = date.getMonth();
        const yyyy = date.getFullYear();

        if (skipYear)
          formatedDate = t('product_preview.tickets.date_without_year', { day: dd, month: months[mm].toLowerCase() });
        else
          formatedDate = t('product_preview.tickets.date', {
            day: dd,
            month: months[mm].toLowerCase(),
            year: yyyy,
          });

        if (isTicketsProduct) {
          formatedDate = `${weekDays[weekDay]} ${formatedDate}`;
        }

        return formatedDate;
      };

      const selectedDate = computed(() => {
        const selectedDate = props.launchingDate ? props.launchingDate : props.availabilityDate;
        return selectedDate ? getReadableDate(selectedDate, props.isTicketsProduct) : '';
      });

      return {
        selectedDate,
      };
    },
  };
</script>

<style></style>
