<template>
  <div
    :id="`${id}_product_preview_title`"
    class="product_preview-info"
    :class="productPeviewTicketsInfoClass">
    <RetailMediaPreviewBadge
      v-if="retailMediaInfo?.retailMediaBadge"
      :retailMediaInfo="retailMediaInfo" />
    <div
      v-if="isTicketsProduct"
      class="product_preview-ticket-title">
      {{ eventCity }}
    </div>
    <Brand
      v-if="showBrand"
      :showLink="false"
      :brand="brand"
      :product-line="productLine"
      className="product_preview" />
    <PreviewLinkToPDP
      :id="id"
      :object-id="objectId"
      :customClass="customClass"
      :clickable="clickable"
      :title="title"
      :url="url"
      :retailMediaInfo="retailMediaInfo" 
      :product-position="productPosition"
      :algolia-data="algoliaData"
      :isCustomUrl="isCustomUrl || isTicketsProduct"
      :class="previewLinkToPDPTicketsClass" />
    <div
      v-if="isTicketsProduct"
      class="product_preview-ticket-place">
      <p>{{ eventPlace }}</p>
    </div>
    <PreviewDates
      v-if="isTicketsProduct"
      :launchingDate="eventDateFrom"
      :isTicketsProduct="isTicketsProduct"
      class="product_preview-ticket-date-from" />
  </div>
</template>

<script>
  import Brand from 'CommonComponents/Product/Brand.vue';
  import PreviewLinkToPDP from 'Components/ProductPreview/PreviewLinkToPDP.vue';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import PreviewDates from './PreviewDates.vue';
  import RetailMediaPreviewBadge from 'Components/RetailMedia/RetailMediaPreviewBadge.vue';

  export default {
    name: 'PreviewInfo',
    components: {
      Brand,
      PreviewLinkToPDP,
      PreviewDates,
      RetailMediaPreviewBadge
    },
    props: {
      customClass: {
        type: String,
        required: false,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      objectId: {
        type: String,
        required: false,
        default: '',
      },
      title: {
        type: String,
        required: true,
      },
      brand: {
        type: Object,
        required: true,
      },
      url: {
        type: String,
        required: true,
      },
      forceShowBrand: {
        type: Boolean,
        default: false,
      },
      clickable: {
        type: Boolean,
        default: true,
      },
      isCustomUrl: {
        type: Boolean,
        default: false,
      },
      productLine: {
        type: String,
        required: false,
        default: '',
      },
      retailMediaInfo:{
        type: [Object, undefined],
        default: undefined
      },
      eventLocation: {
        type: String,
        required: false,
        default: '',
      },
      isTicketsProduct: {
        type: Boolean,
        required: false,
        default: false,
      },
      eventDateFrom: {
        type: String,
        required: false,
        default: '',
      },
      shouldHideBrand: {
        type: Boolean,
        default: false,
      },
      algoliaData: {
        type: Object,
        default: () => undefined,
      },
      productPosition: {
        type: Number,
        required: false,
        default: undefined,
      },
    },
    setup(props) {
      const hasBrandCustomization = mapGetter('page/hasBrandCustomization');
      const getBrandCustomization = mapGetter('page/getBrandCustomization');
      const isApp = mapGetter('page/isApp');

      const showBrand = computed(() => {
        if (props.shouldHideBrand) return false;
        if (isApp.value) return true;
        if (!props.brand || !props.brand.name) return false;
        if (props.forceShowBrand) return true;
        if (!hasBrandCustomization.value) return true;
        return getBrandCustomization?.value?.id != props.brand.value;
      });

      const eventCity = computed(() => {
        return props?.eventLocation?.split('.')?.pop();
      });

      const eventPlace = computed(() => {
        return props?.eventLocation?.split('.')?.[0];
      });

      const productPeviewTicketsInfoClass = computed(() => {
        return {
          'product_preview-tickets-info': props.isTicketsProduct,
        };
      });

      const previewLinkToPDPTicketsClass = computed(() => {
        return {
          'product_preview-link-pdp': props.isTicketsProduct,
        };
      });

      return {
        showBrand,
        eventCity,
        eventPlace,
        productPeviewTicketsInfoClass,
        previewLinkToPDPTicketsClass,
      };
    },
  };
</script>

<style>
  .product_preview-tickets-info {
    padding: 1em;
    word-break: break-word;
  }
  .product_preview-ticket-title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 13px;
  }
  .product_preview-ticket-place {
    font-size: 13px;
    margin-bottom: 10px;
    color: #00862d;
    font: normal 13px / 24px ECI;
  }
  .product_preview-link-pdp {
    margin-bottom: 10px;
  }
  .product_preview-ticket-date-from {
    background-color: #e1e1e1;
    margin-left: -1em;
    margin-right: -1em;
    color: #000;
    font-weight: 700;
    font-size: 11px;
    text-align: left;
    line-height: 13px;
    padding: 0.5em;
  }
</style>
