
import { debounce } from 'CommonUtils/operations/debounce';

const productPreviewLogic = {
  emits: ['putHandleUrl'],
  data() {
    return {
      observerFirst: null,
      observerLast: null,
      topPlacementObserver: null,
      observerOptionsFirstItem: {
        root: null,
        rootMargin: '-40% 0px -40%',
      },
      observerOptionsLastItem: {
        root: null,
        rootMargin: '-10% 0px -10%',
      },
      currentDireccion: 'down',
      currentValue: '',
      oldValue: '',
    }
  },
  methods: {
    onScroll() {
      this.currentValue = window.pageYOffset;
      if (this.oldValue < this.currentValue) {
        this.currentDirection = 'up';
      } else if (this.oldValue > this.currentValue) {
        this.currentDirection = 'down';
      }
      this.oldValue = this.currentValue;
    },
    handleTopPlacement(entries){
      entries.forEach(async entry => {
        const { isIntersecting } = entry;
        if (!isIntersecting) return;
        await this.$nextTick();
        
        this.$emit('putHandleUrl', this.currentPage);
      });
    },
    handleFirst(entries){
      entries.forEach(async entry => {
        const { target, isIntersecting } = entry;
        if (!target.firstRaidMade) {
          target.firstRaidMade = true;
          if (!isIntersecting) return;
        }
        let page = +target.attributes.dataPage.value;
        const firstItemPage = +target.attributes.dataFirst.value;
        const isFirstItemPage = firstItemPage == page;
        if (this.currentDirection === 'down' && !isFirstItemPage) page -= 1;
        if(isFirstItemPage) await this.$nextTick();
        
        this.$emit('putHandleUrl', page);
      });
    },
    handleLast(entries){
      entries.forEach(entry => {
        const { target, isIntersecting } = entry;
        if (!target.firstRaidMade) {
          target.firstRaidMade = true;
          if (!isIntersecting) return;
        }
        let page = +target.attributes.dataPage.value;
        if (this.currentDirection === 'up') page += 1;

        this.$emit('putHandleUrl', page);
      });
    },
    initObserver(){
      // Page Top PLACEMENTS
      const _handleTopPlacement = debounce(this.handleTopPlacement, 100);
      this.topPlacementObserver = new IntersectionObserver(_handleTopPlacement);

      // Page Edges FIRST
      const _handleFirst = debounce(this.handleFirst, 80);
      this.observerFirst = new IntersectionObserver(_handleFirst, this.observerOptionsFirstItem);

      // Page Edges LAST
      const _handleLast = debounce(this.handleLast, 80);
      this.observerLast = new IntersectionObserver(_handleLast, this.observerOptionsLastItem);
    },
  },
  mounted() {
    this.initObserver();
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    if (this.observerFirst) this.observerFirst.disconnect();
    if (this.observerLast) this.observerLast.disconnect();
    if (this.topPlacementObserver.length) this.topPlacementObserver.forEach(observer => observer.disconnect());
  },
};

export default productPreviewLogic;
