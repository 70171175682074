/**
 * Enum for modal show content.
 * @readonly
 * @enum type of modal content
 */
const MODAL_CONTENT_TYPES = Object.freeze({
  SIZES: 'only_size',
  COLORS: 'only_colors',
  BOTH: 'both_size_colors'
});

export default MODAL_CONTENT_TYPES;
