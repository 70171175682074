<template>
  <div
    ref="stars"
    data-testid="bazaar_voice"
    class="bazaar_voice__stars"
    :class="{
      'novisible': hasZeroRating
    }"
    :data-bv-show="layoutType"
    :data-bv-productId="id" />
</template>

<script>
import BAZAAR_VOICE_TYPES from 'Utils/bazaarVoiceTypes';

export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: () => {
        BAZAAR_VOICE_TYPES.RATING_SUMMARY;
      },
    },
  },
  setup(props) {
    const stars = ref(null);
    const hasZeroRating = ref(false);
    
    const handleZeroRating = (element) => {
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          Array.from(mutation.addedNodes).forEach((node) => {
            if(node.length === 0) return;
            const ratingHolder = node.getElementsByClassName('bv_text');
            if(!ratingHolder || !ratingHolder.length) return;
            observer.disconnect();
            hasZeroRating.value = isZeroRating(ratingHolder[0]);
          })
        });
      });
      const observerOptions = {
        attributes: true, 
        childList: true,
      };
      observer.observe(element, observerOptions);
    }
    const isZeroRating = (holder) => {
      const valoration = holder.innerHTML;
      const strValoration = valoration?.trim();
      const intValoration = parseInt(strValoration.substring(1, strValoration?.length), 10);
      return intValoration === 0;
    }
    onMounted(()=>{
      handleZeroRating(stars.value);
    });

    return {
      stars,
      id: props.productId,
      layoutType: props.type,
      hasZeroRating
    };
  },
};
</script>