const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];
const dateToDayLetterMonth = function(dateValue) {
  const str = new Date(dateValue);

  return str.getUTCDate() + ' de ' + months[str.getMonth()];
};

function readableDate(date, skipYear = false) {
  if (!date) return;
  if (
    Object.prototype.toString.call(date) === '[object Date]' &&
    isNaN(date.getTime())
  )
    return;
  const weekday = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const formatedDate = skipYear
    ? weekday + ' de ' + months[month].toLowerCase()
    : weekday + ' de ' + months[month] + ' de ' + year;

  return formatedDate;
}

// Return date format 01/12/2024
const ddmmyy = function(stringDate) {
  if (!stringDate) {
    return;
  }
  const date = new Date(stringDate);
  if (
    Object.prototype.toString.call(date) === '[object Date]' &&
    isNaN(date.getTime())
  ) {
    return;
  }

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`
}

module.exports = { dateToDayLetterMonth, readableDate, ddmmyy };
