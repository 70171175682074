<template>
  <div
    class="units_selector"
    :class="mainClass">
    <button
      id="decreaseUnits"
      :class="reachedMin && 'disabled'"
      :disabled="reachedMin"
      @click="decreaseUnits">
      -
    </button>

    <div
      id="currentUnits"
      :aria-label="$t('product.units_selector.selected_units')"
      class="units_selector__units">
      {{ currentUnits }}
    </div>

    <button
      id="increaseUnits"
      :disabled="isDisabledPlusBtn"
      :class="(isDisabledPlusBtn || maxUnits == currentUnits) && 'disabled'"
      @click="addUnits">
      <div>+</div>
    </button>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    incrementUnitsBy: {
      type: Number,
      default: 1,
    },
    initialUnits: {
      type: Number,
      default: 1,
    },
    isDisabledPlusBtn: {
      type: Boolean,
      default: false,
    },
    mainClass: {
      type: String,
      default: undefined,
    },
    maxUnits: {
      type: Number,
      default: undefined,
    },
    minUnits: {
      type: Number,
      default: 1,
    },
    forceUnit: {
      type: Number,
      default: undefined,
    }
  },
  emits: ['unitsAdded', 'unitsDecreased'],
  setup(props, { emit }) {
    const currentUnits = ref(props.initialUnits);

    const addUnits = () => {
      if (!props.maxUnits || props.maxUnits > currentUnits.value) {
        currentUnits.value += props.incrementUnitsBy;
        emit('unitsAdded', currentUnits.value);
      }
    }

    const decreaseUnits = () => {
      if (currentUnits.value > Math.min(props.minUnits, props.incrementUnitsBy)) {
        currentUnits.value -= props.incrementUnitsBy;
        if (currentUnits.value < 0) currentUnits.value = 0;
        emit('unitsDecreased', currentUnits.value);
      }
    }

    const reachedMin = computed(() => {
      const minUnits = !props.minUnits && (props.minUnits !== 0) ? props.incrementUnitsBy : props.minUnits;
      return currentUnits.value <= minUnits;
    });

    watch(() => props.forceUnit, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        currentUnits.value = newValue
      }
    })

    return {
      addUnits,
      decreaseUnits,
      currentUnits,
      reachedMin,
    };
  },
};
</script>
