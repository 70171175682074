<template>
  <div
    ref="slider"
    class="slider">
    <div class="wrapper">
      <div
        ref="carousel"
        class="carousel"
        role="listbox"
        aria-orientation="horizontal"
        :aria-activedescendant="`${carouselId}_option_${index}`"
        :aria-multiselectable="false"
        :style="cssSlides"
        @scroll="checkIndex">
        <div
          v-for="(item, i) in elementsToRender"
          :id="`${carouselId}_option_${i}`"
          ref="slide"
          :key="`slide_${i}`"
          role="option"
          :aria-selected="index===i"
          class="carousel-item"
          :style="`aspect-ratio:${item.ratio}`"
          :class="{ hideBackgroundImage: hideBackgroundImage }">
          <slot
            :item="item" 
            :index="i" />
        </div>
        <div
          v-if="!loadFullCarousel"
          class="carousel-item"></div>
      </div>
    </div>
    <template v-if="isSlideable && isMounted && !isModalSizeOpen">
      <a
        v-if="!isFirstIndex"
        href="javascript:void(0)"
        class="carousel-control carousel-control-backward slider-icon"
        :class="classBackward"
        :aria-label="$t('product.carousel.previous_image')"
        @[isDesktopDevice&&`mouseover`].stop="$emit('closeModalSizes')"
        @click.stop="handleShift(DIRECTIONS.LEFT)" />
      <a
        v-if="!isLastIndex"
        href="javascript:void(0)"
        class="carousel-control carousel-control-forward slider-icon"
        :class="classForward"
        :aria-label="$t('product.carousel.next_image')"
        @[isDesktopDevice&&`mouseover`].stop="$emit('closeModalSizes')"
        @click.stop="handleShift(DIRECTIONS.RIGHT)" />
    </template>
  </div>
</template>

<script>
  import Validator from 'CommonUtils/validator';
  import { uniqWith } from 'CommonUtils/operations/uniqWith';
  import { isEqual } from 'CommonUtils/operations/isEqual';
  import { isEmpty } from 'CommonUtils/operations/isEmpty';
  import { take } from 'CommonUtils/operations/take';
  import { castArray } from 'CommonUtils/operations/castArray';
  import { mapGetters, mapActions } from 'vuex';

  const DIRECTIONS = { RIGHT: 'RIGHT', LEFT: 'LEFT' };
  export default {
    name: 'CarouselImages',
    props: {
      /**
       * Item/s a pintar en el slot del carousel.
       * Puede ser un string o un array.
       * Cada item será un slide.
       * El tamaño se tomará en base a la imagen más grande y el ancho del contenedor.
       * El comportamiento de las imágenes se define en la propiedad mediaFit
       */
      items: {
        type: [Array, String],
        required: true,
        validator: (val) => !isEmpty(castArray(val)),
      },
      /**
       * Activa o desactiva la funcionalidad de carousel.
       */
      active: {
        type: Boolean,
        default: true,
      },
      /**
       * Limita los elementos de item al número indicado.
       * 0 no limitará.
       */
      limit: {
        type: Number,
        default: 0,
        validator: (val) => val >= 0,
      },
      /**
       * Límite en el que se considera que al dejar de arrastrar pasará de slide.
       */
      threshold: {
        type: Number,
        default: 0.01,
      },
      /**
       * Transición a aplicar al slide.
       * Se aplica directamente a la propiedad 'left', no es necesario indicarlo.
       */
      shiftingTransition: {
        type: String,
        default: '0.2s ease-out',
        validator: (val) => Validator.isValidTransition(val),
      },
      /**
       * Valor para la propiedad object-fit que aplica a <img> o <video> dentro de cada slide.
       * Sintaxis: fill | (en-US) contain | (en-US) cover | (en-US) none | (en-US) scale-down
       */
      mediaFit: {
        type: String,
        default: 'cover',
        validator: (val) => Validator.isValidObjectFit(val),
      },
      /**
       * Mostrar los controles para deslizar en todo momento.
       * Por defecto, mostrará en base a un IntersectionObserver y al hacer hover en desktop.
       */
      alwaysShowControl: {
        type: Boolean,
        default: false,
      },
      /**
       * Selecciona en qué elemento comenzará el slide.
       */
      firstItemIndex: {
        type: Number,
        default: 0,
      },
      /**
       * Desliza al índice correspondiente
       */
      selectedItemIndex: {
        type: Number,
        default: 0,
      },
      /**
       * Clase que define los controles del slide.
       * Por defecto usará 'default': slider-icon--default.
       * Ejemplo para el valor 'special': slider-icon--special.
       */
      sliderIconClass: {
        type: String,
        default: 'default',
      },
      /**
       * True para que elimine los elementos repetidos
       */
      uniq: {
        type: Boolean,
        default: true,
      },
      /**
       * Límite del ángulo que idetifica si el gesto es vertical u horizontal.
       */
      gestureDirectionBreakpoint: {
        type: Number,
        default: 60,
        validator: function (value) {
          return value >= 0 && value <= 90;
        },
      },
      hideBackgroundImage: {
        type: Boolean,
        default: false,
      },
      isServer: {
        type: Boolean,
        required: true,
      },
      isDesktopDevice: {
        type: Boolean,
        required: true,
      },
      carouselId: {
        type: String,
        required: true,
      }
    },
    emits: ['loaded','closeModalSizes'],
    data() {
      return {
        slider: null,
        carrousel: null,
        slides: null,
        slideSize: 0,
        x: 0,
        posX1: 0,
        posY1: 0,
        index: 0,
        loadFullCarousel: false,
        isDragging: false,
        isValidEventHappeging: false,
        draggingDirection: null,
        allowShift: true,
        shifting: false,
        showControl: false,
        iObserver: null,
        rObserver: null,
        slidesLeft: null,
        slidesWidth: null,
        loaded: [0],
        DIRECTIONS,
        isMounted: false
      };
    },
    computed: {
      ...mapGetters('selectorState', {
        isModalSizeOpen: 'getModalStatus'
      }),
      elements() {
        let elements = castArray(this.items);
        if (this.uniq) elements = uniqWith(elements, isEqual);
        if (this.firstItemIndex)
          elements = elements.slice(this.firstItemIndex).concat(take(elements, this.firstItemIndex));
        if (!this.active) return take(elements, 1);
        if (this.limit) return take(elements, this.limit);
        
        return elements;
      },
      elementsToRender() {
        return this.loadFullCarousel || this.firstItemIndex > 0 ? this.elements : this.elements.slice(0, 1);
      },
      isSlideable() {
        return this.elements.length > 1;
      },
      cssSlides() {
        return {
          '--shifting-transition': this.shiftingTransition,
          '--object-fit': this.mediaFit,
        };
      },
      classBackward() {
        return [
          `slider-icon--${this.sliderIconClass}`,
          `slider-icon--${this.sliderIconClass}-backward`,
          { 'slider-icon--active': this.showControl },
          { 'slider-icon--always-active': this.alwaysShowControl },
        ];
      },
      classForward() {
        return [
          `slider-icon--${this.sliderIconClass}`,
          `slider-icon--${this.sliderIconClass}-forward`,
          { 'slider-icon--active': this.showControl },
          { 'slider-icon--always-active': this.alwaysShowControl },
        ];
      },
      isFirstIndex() {
        return this.index === 0;
      },
      isLastIndex() {
        return this.index === this.items.length - 1;
      }
    },
    mounted() {
      this.wrap();
      this.isMounted = true;
    },
    unmounted() {
      this.destroyObservers();
    },
    methods: {
      ...mapActions('selectorState', ['showModal']),
      wrap() {
        this.setNodeRefs();
        this.initObservers();
        this.setSizes();
      },
      handleShift(direction) {
        // Bundling the changes of index and loadFullCarousel in the same event loop breaks the Vue animation
        this.loadFullCarousel = true;
        nextTick(() => {
          const LIMITS = { RIGHT: this.elements.length - 1, LEFT: 0 };
          if (direction === DIRECTIONS.RIGHT) {
            this.index = this.index === LIMITS.RIGHT ? LIMITS.LEFT : this.index + 1;
          } else if (direction === DIRECTIONS.LEFT) {
            this.index = this.index === LIMITS.LEFT ? LIMITS.RIGHT : this.index - 1;
          }
          this.carousel.scrollLeft = this.index * this.slideSize;
          if (!this.loaded.includes(this.index)) this.loaded.push(this.index);
        });
      },
      setSizes() {
        this.slideSize = this.slider.getBoundingClientRect().width;
        this.carousel.scrollLeft = this.index * this.slideSize;
        this.$emit('loaded', this.slideSize);
      },
      setNodeRefs() {
        this.slider = this.$refs.slider;
        this.carousel = this.$refs.carousel;
        this.slides = this.$refs.slide;
      },
      initObservers() {
        const options = {
          rootMargin: '0% 0% -20% 0%',
          threshold: this.threshold,
        };
        this.iObserver = new IntersectionObserver(this.handleIntersection, options);
        this.rObserver = new ResizeObserver(this.handleResize);
        this.iObserver.observe(this.slider);
        this.rObserver.observe(this.slider);
      },
      handleIntersection(entries) {
        entries.map((entry) => {
          this.showControl = entry.isIntersecting;
        });
      },
      handleResize(entries) {
        entries.map(() => {
          this.setSizes();
        });
      },
      destroyObservers() {
        this.iObserver.unobserve(this.slider);
        this.rObserver.unobserve(this.slider);
      },
      checkIndex(){
        this.loadFullCarousel = true;
        this.index = Math.round((this.carousel.scrollLeft / this.carousel.scrollWidth) * this.elements.length);
      }
    },
  };
</script>
