<template>
  <p
    v-if="previewDate"
    class="preview-dates">
    {{ previewDate }}
  </p>
</template>

<script>
export default {
  name: 'PreviewDates',
  props: {
    previewDate: {
      type: String,
      default: ''
    },
  }
}
</script>