<template>
  <SelectorProductModalDesktop
    :is-open="isMounted && showModal"
    :type-of-variant="''"
    :is-coming-soon="false"
    :product-uri="productUri">
    <div class="modal-actions-go_to_pdp">
      <a
        class="modal-actions-go_to_pdp--text"
        :href="productUri"
        @click="sendProductClick">
        {{ $t('global.show_detail') }}
      </a>
    </div>
  </SelectorProductModalDesktop>
</template>

<script>
  import UtilsAnalytics from 'CommonUtils/analytics';
  import SelectorProductModalDesktop from './SelectorProductModalDesktop.vue';
  import UtilsCookies from 'CommonUtils/cookies.js';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import AlgoliaEvents from 'CommonUtils/algoliaEvents.js';

  export default {
    name: 'SelectorGoToPDP',
    components: {
      SelectorProductModalDesktop,
    },
    props: {
      productUri: {
        type: String,
        required: true,
      },
      showModal: {
        type: Boolean,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      objectId: {
        type: String,
        required: false,
        default: '',
      },
      productPosition: {
        type: Number,
        required: false,
        default: 1,
      },
      algoliaData: {
        type: Object,
        default: () => undefined,
      },
    },
    setup(props) {
      const crossSellingType = inject('crossSellingType');
      const getDataLayer = inject('getDataLayer');
      const isRichRelevance = inject('isRichRelevance');
      const isMounted = ref(false);
      const getSearchSource = mapGetter('page/getSearchSource');
      const algoliaInfo = mapGetter('page/getAlgoliaInfo');
      const algoliaUser = mapGetter('cart/getAlgoliaUserData');
      AlgoliaEvents.initAlgoliaEventsUrl(algoliaInfo.value?.events);

      const isAlgoliaEnabled = computed(() => {
        return algoliaInfo.value?.enabled && getSearchSource.value === 'algolia';
      });

      const sendProductClick = () => {
        if (props.algoliaData?.query_id) {
          UtilsCookies.setCookie(
            'algolia_pdp',
            JSON.stringify({
              id: props.id,
              query_id: props.algoliaData?.query_id,
              index_name: props.algoliaData?.index_name,
              position: props.productPosition,
            }),
          );
        }
        if (isAlgoliaEnabled.value) {
          const payload = {
            index: props.algoliaData?.index_name,
            queryID: props.algoliaData?.query_id,
            objectIDs: [props.objectId],
            positions: [props.productPosition],
            userToken: algoliaUser.value?.algoliaUserToken,
            authenticatedUserToken: algoliaUser.value?.algoliaAuthenticatedUserToken,
          };
          AlgoliaEvents.sendClickedObjectIDsAfterSearch(payload);
        }
        UtilsAnalytics.sendProductPreviewLinkToPDPClick(getDataLayer(), crossSellingType, isRichRelevance);
      };

      onMounted(() => {
        isMounted.value = true;
      });

      return {
        isMounted,
        sendProductClick,
      };
    },
  };
</script>
