<template>
  <div
    v-if="product"
    class="product-compare">
    <input
      :id="inputId"
      v-model="productCompareModel"
      :disabled="inputCheckDisabled"
      type="checkbox"
      class="product-compare__input" />
    <span
      role="checkbox"
      tabindex="0"
      class="product-compare__check"
      :aria-checked="productCompareModel"
      :aria-labelledby="labelId"
      @click="toggleCompare">
    </span>
    <label
      :id="labelId"
      :for="inputId"
      class="product-compare__label"
      @click="handleLabelClick">
      {{ $t('comparator.product.compare') }}
    </label>
  </div>
</template>

<script>
  import useProductCompare from 'Composables/useProductCompare.js';

  export default {
    name: 'ProductCompare',
    props: {
      product: {
        type: Object,
        required: true,
        default: () => undefined
      }
    },
    setup(props) {
      const { product: currentProduct } = toRefs(props);
      const inputId = computed(() => `compare-input--${currentProduct.value?.id}`);
      const labelId = computed(() => `compare-label--${currentProduct.value?.id}`);

      const {
        toggleCompare,
        productCompareModel,
        inputCheckDisabled
      } = useProductCompare(currentProduct);

      const handleLabelClick = (event) => {
        if (!inputCheckDisabled.value) return;
        event.preventDefault();
        toggleCompare();
      };

      return {
        inputId,
        labelId,
        toggleCompare,
        handleLabelClick,
        productCompareModel,
        inputCheckDisabled
      };
    },
  };
</script>
