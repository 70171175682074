<template>
  <div class="promo-badges__container">
    <PromoBadge
      :badgePromotions="promotionsDisplayed"
      :labelProp="LABEL_PROP" />
    <a
      v-if="hasSeveralPrincipalPromos"
      class="promo-badges__more-promos"
      :href="productURL">
      {{ $t('assets.promos.title.promociones') }}
    </a>
  </div>
</template>
<script>
  import PromoBadge from './PromoBadge.vue';
  import useBadgeText from 'Composables/promotions/useBadgeText.js';

  const LABEL_PROP = 'title_list';

  export default {
    components: { PromoBadge },
    props: {
      badgePromotions: {
        type: Array,
        default: () => [],
      },
      productURL: {
        type: String,
        default: '',
      },
      promosPerProduct: {
        type: Number,
        required: false,
        default: 1
      },
    },
    setup(props) {
      const { hasSeveralPrincipalPromos, promotionsDisplayed } = useBadgeText(props, LABEL_PROP);
    
      return {
        hasSeveralPrincipalPromos,
        LABEL_PROP,
        promotionsDisplayed,
      };
    },
  };
</script>
