<template>
  <div class="badge-agrupation-container">
    <div class="badge-agrupation">
      <div
        v-if="dataAgrupation && dataAgrupation.gif"
        data-testid="badgeGif"
        role="img"  
        name="badgeGif"  
        class="badge_gif--container">
        <img
          :alt="dataAgrupation.description"
          :title="dataAgrupation.description"
          :src="gifUrl" />
      </div>
      <div class="badge-agrupation-text">
        <span
          v-if="textTitle"
          class="badge-agrupation-text--title bold"> {{ textTitle }}
        </span>
        <span
          v-if="textDescription"
          class="badge-agrupation-text--description"> {{ textDescription }}
        </span>
        <PromoBadgeLink
          v-if="dataAgrupation.text && isPDP && !isIaChat"
          :customClass="'agrupation'"
          :label="dataAgrupation.text"
          :promoBadgeUrl="promoUrl"
          :windowType="dataAgrupation.window_type"
          @promoSelected="emitPromotionIframe" /> 
        <span 
          v-else-if="dataAgrupation.text && isPDP"
          class="badge-agrupation-text--promo">{{ dataAgrupation.text }}
        </span>
        <span
          v-if="textDate && isPDP"
          class="badge-agrupation-text--dates"> {{ textDate }}
        </span>
      </div>
    </div>
    <div 
      v-if="!isPDP"
      class="badge-agrupation-text--footer">
      <PromoBadgeLink
        v-if="dataAgrupation.text && !isIaChat"
        :customClass="'agrupation'"
        :label="dataAgrupation.text"
        :promoBadgeUrl="promoUrl"
        :windowType="dataAgrupation.window_type"
        @promoSelected="emitPromotionIframe" /> 
      <span 
        v-else-if="dataAgrupation.text"
        class="badge-agrupation-text--promo">{{ dataAgrupation.text }}
      </span>
      <span
        v-if="textDate"
        class="badge-agrupation-text--dates"> {{ textDate }}
      </span>
    </div>
  </div>
</template>
<script>

import { mapGetter } from 'CommonUtils/store/state.js';
import  dateFilters  from 'CommonUtils/dateFilters';
import PromoBadgeLink from 'CommonComponents/PromoBadge/PromoBadgeLink.vue';

  export default {
    components: { 
      PromoBadgeLink
    },
    props: {
      dataAgrupation: { 
        type: Object, 
        required: true 
      },
      price: {
        type: Object,
        required: true,
        default: () => {},
      },
      isIaChat: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    emits: ['promotionIframe'],
    setup(props, { emit }) {
      
      const isPDP = mapGetter('page/getIsPdp');
      const { title, description } = props.dataAgrupation;

      const gifUrl = computed(() => {
        const { gif } = props.dataAgrupation;
        return gif;
      });

      const promoUrl = computed(() => {
        const { urlPromo } = props.dataAgrupation;
        return urlPromo;
      });

      const textTitle = computed(() => {
        const { unit_price_agrupation } = props.price;

        if (!title) {
          return '';
        }
        if (!unit_price_agrupation) {
          return title;
        }
        return `${title} ${unit_price_agrupation} €`;
      });

      const textDescription = computed(() => {
        const { lot_price, unit_price_pum } = props.price;

        if (!description) {
          return '';
        }

        if (!lot_price) {
          return description;
        }
        if (!unit_price_pum) {
          return `${description} ${lot_price} €`;
        }
        return `${description} ${lot_price} € (${unit_price_pum})`;
      });

      const textDate = computed(() => {
        const { startDate, endDate } = props.dataAgrupation;
        if (!startDate || !endDate) {
          return;
        }
        const startDateFormatted = dateFilters.ddmmyy(startDate);
        const endDateFormatted = dateFilters.ddmmyy(endDate);
        return `Oferta del ${startDateFormatted} al ${endDateFormatted}`
      })

      function emitPromotionIframe(iframeURL) {
        emit('promotionIframe', iframeURL);
      }

      return { 
        gifUrl, 
        isPDP, 
        textDate, 
        textDescription, 
        textTitle, 
        promoUrl,   
        emitPromotionIframe,
      };
    },
  };
</script>