<template>
    <meta v-if="name" itemprop="name" :content="name"/>
    <link v-if="canonical" itemprop="url"  :href="canonical"/>
    <meta v-if="image" itemprop="image" :content="image"/>
    <meta v-if="reference" itemprop="sku" :content="reference" />
    <meta v-if="gtin" itemprop="gtin" :content="gtin" />
</template>

<script>
export default {
  name: 'SEO',
  props: {
    name: {
      type: String,
      required: false
    },
    canonical: {
      type: String,
      required: false
    },
    image: {
      type: String,
      required: false
    },
    reference: {
      type: String,
      required: false
    },
    gtin: {
      type: String,
      required: false
    }
  }
}
</script>

<style>

</style>