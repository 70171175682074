<template>
  <div class="color-product-preview">
    <BtnAddToCart
      :product="product"
      :colors-collection="colorsCollection"
      class="color-product-preview-button"
      :text="colorsCollection.length + ' ' + $t('global.colours')"
      :button-type="gridType == GRID_TYPES.LITTLE_PRODUCTS ? MODAL_CONTENT.BOTH : MODAL_CONTENT.COLORS"
      :show-only-colors="true" />
  </div>
</template>

<script>
  import BtnAddToCart from 'Components/assets/AddCart/BtnAddToCart.vue';
  import MODAL_CONTENT from 'CommonUtils/modalContent';
  import GRID_TYPES from 'CommonUtils/gridTypes';

  export default {
    name: 'BtnColors',
    props: {
      product: {
        type: Object,
        required: true,
      },
      gridType: {
        type: String,
        required: true,
      },
      colorsCollection: {
        type: Array,
        required: true,
        default: [],
      },
    },
    data() {
      return {
        MODAL_CONTENT,
        GRID_TYPES,
      };
    },
    components: {
      BtnAddToCart,
    },
  };
</script>
