import { ref, onUpdated } from 'vue'

const useShowMoreOverflow = (el) => {
  const isOverflowing = ref(false)
  const showMore = ref(false)

  const checkOverflow = () => {
    if(!el.value) return

    const currentOverflow = el.value.style.overflowY
    if(!currentOverflow || currentOverflow === "visible") el.value.style.overflowY = "hidden"
    
    isOverflowing.value = el.value.clientWidth < el.value.scrollWidth || el.value.clientHeight < el.value.scrollHeight
    el.value.style.overflowY = currentOverflow
  }
  const resetShowMore = () => {
    isOverflowing.value = false
    showMore.value = false
  }
  const toggleShowMore = () => {
    el.value.scrollTop = 0
    showMore.value = !showMore.value
  }

  onUpdated(checkOverflow)

    return {
      isOverflowing,
      showMore,
      checkOverflow,
      resetShowMore,
      toggleShowMore
    }
}

export default useShowMoreOverflow