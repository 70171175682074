<template>
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 6.50049C11.4142 6.50049 11.75 6.83627 11.75 7.25049C11.75 7.6647 11.4142 8.00049 11 8.00049C10.5858 8.00049 10.25 7.6647 10.25 7.25049C10.25 6.83627 10.5858 6.50049 11 6.50049Z"
      fill="black" />
    <path
      d="M11.5999 10.5005V16.5005H10.3999V10.5005H11.5999Z"
      fill="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9999 0.900391C5.14568 0.900391 0.399902 5.64617 0.399902 11.5004C0.399902 17.3546 5.14568 22.1004 10.9999 22.1004C16.8541 22.1004 21.5999 17.3546 21.5999 11.5004C21.5999 5.64617 16.8541 0.900391 10.9999 0.900391ZM1.5999 11.5004C1.5999 6.30891 5.80843 2.10039 10.9999 2.10039C16.1914 2.10039 20.3999 6.30891 20.3999 11.5004C20.3999 16.6919 16.1914 20.9004 10.9999 20.9004C5.80843 20.9004 1.5999 16.6919 1.5999 11.5004Z"
      fill="black" />
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon'
}
</script>
