<template>
  <button
    @click.prevent="openSizeModal">
    <a
      :href="'#' + product.id + 'anchor'"
      class="btn-text">{{
        text
      }}</a>
  </button>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    inject: ['productData', 'productWrapper'],
    props: {
      product: {
        type: Object,
        required: true,
      },
      colorsCollection: {
        type: Array,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      buttonType: {
        type: String,
        required: true,
      },
      showOnlyColors: {
        type: Boolean,
        value: false
      }
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      ...mapActions('selectorState', ['showModal']),
      ...mapActions('uiState', {
      showCustomerNotificationModal: 'showModal'
      }),
      closeModal() {
        const payload = { isOpen: false };
        this.showModal(payload);
      },
      async openSizeModal() {
        if (!this.colorsCollection.length) return;
        if (this.isEmptyVariant && !this.showOnlyColors) return this.directAddToCart();
        await this.closeModal();
        this.isOpen = true;

        const payload = {
          currentWrapper: this.productWrapper,
          currentProduct: this.product.id,
          isOpen: this.isOpen,
          buttonType: this.buttonType,
          showOnlyColors: this.showOnlyColors
        };

        this.showModal(payload);
        const queryAnchorProductToScroll = `#${this.product.id} .anchor-product-to-scroll`;
        const anchor = document.querySelector(queryAnchorProductToScroll);
        if (anchor) anchor.scrollIntoView({ behavior: 'smooth' });
      },
      directAddToCart() {
        const size = this.preselectedColor[0].variants?.[0];
        if (!size) return;

        if (this.preselectedColor[0].coming_soon) {
          return this.openCustomerNotificationModal(size);
        }

        if (this.preselectedColor[0].not_available) return;

        this.$emit('directAddToCart', size);
      },
      openCustomerNotificationModal(size) {
        if (!this.isCustomerNotificationOpen && this.preselectedColor[0].variants?.[0]?.show_notify) {
          const variantData = {
            ...size,
            productData: this.productData,
            color: this.preselectedColor?.[0]?.title,
            similarProducts: []
          };
          const payload = {
            name: 'modalCustomerNotification',
            status: {
              isOpen: true,
              variant: variantData,
            }
          };
          this.showCustomerNotificationModal(payload);
        }
      },
    },
    computed: {
      ...mapGetters('selectorState', {
        isModalSizeOpen: 'getModalStatus',
      }),
      ...mapGetters('uiState', {
        isCustomerNotificationOpen: 'getStatusCustomerNotification',
      }),
      preselectedColor() {
        return this.colorsCollection.filter((x) => x.preselected);
      },
      isEmptyVariant() {
        if (this.preselectedColor.length === 0) return false;
        const color = this.preselectedColor[0];

        return color.variants?.[0].variant?.empty;
      }
    },
  };
</script>
