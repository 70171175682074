<template>
  <span
    v-if="show"
    class="size_units-label">
    {{ message }}
  </span>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapGetter } from 'CommonUtils/store/state.js';

const MAXIMUM_AVAILABLE_UNITS = 100000;

export default {
  props: {
    availableUnits: {
      type: Number, 
      default: 0,
      required: false
    }
  },
  setup(props){
    const customization = mapGetter('page/getLayoutOptions');
    const limitUnits = customization?.max_online_stock_units?.value || MAXIMUM_AVAILABLE_UNITS;
    const { t } = useI18n();
    const show = computed(() => {
      return Boolean(props.availableUnits) && props.availableUnits < limitUnits;
    });
    const message = computed(() => {
      if (!props.availableUnits) {
        return undefined;
      }
      if (props.availableUnits === 1) {
        return t('product.available_units.single');
      }
      return t('product.available_units.multiple', { availableUnits: props.availableUnits });
    });
    return {
      show,
      message
    }
  }
}
</script>