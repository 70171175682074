const DELIVERY_TYPES_MESSAGES = Object.freeze({
  ONLY_CLICKANDEXPRESS: 'stock.delivery.EXPRESS',
  eci_express: 'stock.delivery.EXPRESS',
  fast_delivery: 'stock.delivery.FAST',
  click_and_car: 'stock.delivery.CLICKCAR',
  S48: 'stock.delivery.S48',
  S24: 'stock.delivery.S24',
  V48: 'stock.delivery.V48',
  V05: 'stock.delivery.V05',
});

export default DELIVERY_TYPES_MESSAGES;
