<template>
  <picture
    v-lazyload="{ resolutions: resolutions, image: getSelectedImage }">
    <source
      v-for="(resolution, index) in resolutions"
      :key="`${resolution.size}_${index}`"
      :srcset="getImageUrl(resolution)"
      :media="resolution.media" />
    <img
      :loading="loadingAttr"
      :fetchpriority="fetchPriorityAttr"
      class="js_preview_image"
      :src="getFallbackImage()"
      :alt="$t('global.image_with_product_title_and_position', { position: image.index, productTitle: title })"
      :style="{'object-fit': image?.item?.ratio === 1 ? 'contain' : 'cover' }" />
  </picture>
</template>

<script>
  import { computed } from 'vue';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import { getImageBySource } from 'CommonUtils/productImage';
  import UtilsImpolicyResolutions from 'CommonUtils/schemas/imageImpolicyResolutionsTypes.js';

  const getImageWithImpolicy = (imageUrl, impolicy, width = 540) => {
    if (!imageUrl) return '';
    const formattedUrl = new URL(imageUrl);
    const params = new URLSearchParams(formattedUrl.searchParams);
    const hasImpolicy = params.has('impolicy');
    const hasWidth =  params.has('width');
    if (!hasImpolicy)  params.append('impolicy', impolicy);
    if (!hasWidth) params.append('width', parseInt(Number(width)));
    if (!hasImpolicy || !hasWidth) formattedUrl.search = params.toString();
    return formattedUrl.toString();
  };

  const loadImage = (el, binding) => {
    const imageElement = Array.from(el.children).find((el) => el.nodeName === 'IMG');
    if(!imageElement) return;
    imageElement.addEventListener('load', () => el.classList.add('loaded'));
    imageElement.addEventListener('error', (event) => console.log('error', event));
    const resolutionSizes = binding.value?.resolutions;
    const image = binding.value?.image;
    const sources = Array.from(el.children).filter((element) => element.nodeName === 'SOURCE');
    if(!sources || !sources.length) return;
    resolutionSizes.forEach((resolution, index)=>{
      if(!sources[index]) return;
      sources[index].srcset = getImageWithImpolicy(image, UtilsImpolicyResolutions.IMPOLICY_RULES.RESIZE, resolution.size);
    });
  }

  const LazyImageDirective = {
    beforeMount: (el, binding) => {
      const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            observer.unobserve(el);
            const ssrLoaded = Array.from(el.children)
              .filter((element) => element.nodeName === 'SOURCE')
              .some((source) => {
                return source.hasAttribute('srcset') && source.srcset !== ''
              });
            if(ssrLoaded) return;
            loadImage(el, binding); 
          }
        }
      };

      const createObserver = () => {
        const options = {
          root: null,
          threshold: '0.001',
        };
        const observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(el);
      };
      if (window['IntersectionObserver']) {
        createObserver();
      } else {
        loadImage(el);
      }
    }
  };

  export default {
    name: 'LazyImage',
    directives: {
      lazyload: LazyImageDirective,
    },
    props: {
      image: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      resolutions: {
        type: Object,
        required: true,
      },
      productPosition: {
        type: Number,
        required: false,
        default: 0
      }
    },
    setup(props) {
      const HIGH_PRIORITY_VISIBILITY_NUMBER = {
        little_products: 4,
        big_products: 2,
      };
      const LAZYLOAD_VALUES = {
        EAGER: 'eager',
        LAZY: 'lazy'
      };
      const FETCHPRIORITY_VALUES = {
        HIGH: 'high',
        MEDIUM: 'medium',
        LOW: 'low',
        AUTO: 'auto'
      };
      const grid = mapGetter('page/getGrid');
      const isServer = mapGetter('page/isServer');
      const getSelectedImage = computed(() => {
        if (props.image.item?.zoom) return props.image.item.zoom;
        if (props.image.item?.big) return props.image.item.big;
        if (props.image.item?.medium) return props.image.item.medium;
        if (props.image.item?.small) return props.image.item.small;
        return '';
      });
      const getImageUrl = (size) => {
        return getImageWithImpolicy(
          getSelectedImage.value, 
          UtilsImpolicyResolutions.IMPOLICY_RULES.RESIZE, 
          size.size
        );
      };
      const getFallbackImage = () => {
        return getImageBySource(props.image.item);
      }
      const HIGH_PRIORITY = HIGH_PRIORITY_VISIBILITY_NUMBER[grid.value] ? HIGH_PRIORITY_VISIBILITY_NUMBER[grid.value] : 4;
      const loadingAttr = computed(() => {
        if(isServer){
          const shouldBeInmmediate = props.image.index === 0 && props.productPosition <= HIGH_PRIORITY;
          if(shouldBeInmmediate) {
            return LAZYLOAD_VALUES.EAGER;
          }
          return LAZYLOAD_VALUES.LAZY;
        }
        return false;
      });
      const fetchPriorityAttr = computed(() => {
        if(isServer){
          const shouldBeInmmediate = props.image.index === 0 && props.productPosition <= HIGH_PRIORITY;
          if(shouldBeInmmediate) {
            return FETCHPRIORITY_VALUES.HIGH;
          }
          return FETCHPRIORITY_VALUES.AUTO;
        }
        return false;
      });

      return {
        getSelectedImage,
        getImageUrl,
        getFallbackImage,
        loadingAttr,
        fetchPriorityAttr
      };
    },
  };
</script>