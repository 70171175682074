<template>
  <div class="sizes-product-preview">
    <span
      v-if="isComingSoon && ((emptyVariant && showCustomerNotification) || !emptyVariant)"
      class="coming-soon__label">
      {{ comingSoonLabel }}
    </span>
    <BtnRedirectToPDP
      v-if="shouldRedirectToPDP"
      :colorSelected="color"
      :disabled="isRedirectToPDPDisabled"
      :class="btnRedirectToPDPClass"
      :destinationUrl="urlToPDP" />
    <BtnAddToCart
      v-else
      :product="product"
      :disabled="isAddToCartDisabled"
      :class="btnAddToCartClass"
      :colors-collection="colorsCollection"
      :text="btnAddToCartText"
      :button-type="gridType == GRID_TYPES.LITTLE_PRODUCTS ? MODAL_CONTENT.BOTH : MODAL_CONTENT.SIZES"
      @directAddToCart="directAddToCart" />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import BtnAddToCart from 'Components/assets/AddCart/BtnAddToCart.vue';
import BtnRedirectToPDP from 'Components/assets/RedirectToPDP/BtnRedirectToPDP.vue';
import GRID_TYPES from 'CommonUtils/gridTypes';
import MODAL_CONTENT from 'CommonUtils/modalContent';
import StockSchema from 'CommonUtils/schemas/stock';

export default {
  name: 'BtnBoth',
  components: {
    BtnAddToCart,
    BtnRedirectToPDP,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => undefined
    },
    isAvailable: {
      type: Boolean,
      required: true,
      default: false
    },
    isBookable: {
      type: Boolean,
      default: false,
    },
    isComingSoon: {
      type: Boolean,
      required: false,
      default: false,
    },
    gridType: {
      type: String,
      required: true,
      default: GRID_TYPES.LITTLE_PRODUCTS
    },
    colorsCollection: {
      type: Array,
      required: true,
      default: () => [],
    },
    isAddingToCart: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['directAddToCart'],
  setup(props, { emit }){
    const { t } = useI18n();
    const comingSoonLabel = t(StockSchema.getStatusLabel(StockSchema.STATUS.COMING_SOON));
    const reserveLabel = t(StockSchema.getStatusLabel(StockSchema.STATUS.RESERVE));

    const color = computed(() => props.colorsCollection.find(({ preselected }) => preselected));
    
    const showCustomerNotification = computed(() => color.value?.show_notify);

    const emptyVariant = computed(() => {
      if (!color.value?.variants?.length) return;

      const [variant] = color.value.variants;
      return variant.variant?.empty && variant;
    });


    const hasCalledStock = computed(() => {
      return props.product?.stock && Object.keys(props.product.stock).length;
    })

    const isAddToCartDisabled = computed(() => {
      if(!hasCalledStock.value) return true;
      if (props.isAvailable) return false;

      if (emptyVariant.value) {
        return !(props.isComingSoon && emptyVariant.value?.show_notify);
      }
      return !(props.isComingSoon && showCustomerNotification.value);
    });

    const btnAddToCartClass = computed(() => {
      return {
        btnAddToCart: true,
        'btnAddToCart--not-available': isAddToCartDisabled.value,
      };
    });

    const btnAddToCartText = computed(() => {   
      if (props.isAddingToCart) {
        return t('global.adding');
      }
      if (props.isBookable) {
        return reserveLabel;
      }
      if (props.isComingSoon) {
        return showCustomerNotification.value ? t('product.customer_notification') : comingSoonLabel;
      }
      if (!props.isAvailable) return t('product.sold_out');
      return t('global.add');
    });

    const directAddToCart = (size) => {
      emit('directAddToCart', size);
    };

    const shouldRedirectToPDP = computed(() => {
      if(props.product.redirectToPDP || props.product?.isTicketsProduct){
        return true;
      }
      return false;
    });

    const urlToPDP = computed(() => {
      if(props.product?.isTicketsProduct) {
        return props.product?.product_url
      }
      return props.product.richRelevanceUrl
    })

    const isRedirectToPDPDisabled = computed(() => {
      return !props.isAvailable;
    });

    const btnRedirectToPDPClass = computed(() => {
      return {
        btnAddToCart: true,
        'btnAddToCart--not-available': isRedirectToPDPDisabled.value,
      };
    });

    return {
      btnAddToCartClass,
      btnAddToCartText,
      btnRedirectToPDPClass,
      isRedirectToPDPDisabled,
      directAddToCart,
      emptyVariant,
      GRID_TYPES,
      MODAL_CONTENT,
      shouldRedirectToPDP,
      showCustomerNotification,
      isAddToCartDisabled,
      comingSoonLabel,
      color,
      urlToPDP
    };
  }
};
</script>

<style></style>
