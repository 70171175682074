<template>
  <div>
    <button
      class="retail-media-promotion-badge" 
      @click="toggleRetailMediaModal">
      <span>{{ adSponsoredText }}</span>
      <InfoIcon
        :width="'12'"
        :height="'12'"
        :heightViewBox="'12'"
        :path="'#018639'" />
    </button>
    <template v-if="showPromotionalModal">
      <Teleport to="#Layout-modal-promotional-product">
        <Modal
          :mainClass="modalStyle"
          :modalBodyClass="'retail_media_container__modal_body'"
          :showHeaderLayer="false"
          @close="closeModal">
          <template #body>
            <h3>{{ $t('retailmedia.ad-about-text') }}</h3>
            <p class="bold">
              {{ $t('retailmedia.ad-why-question-text') }}
            </p>
            <p>{{ $t('retailmedia.ad-why-answer-text') }}</p>
            <ul>
              <li>{{ $t('retailmedia.ad-why-answer-reason-1-text') }}</li>
              <li>{{ $t('retailmedia.ad-why-answer-reason-2-text') }}</li>
            </ul>
            <p class="bold">
              {{ $t('retailmedia.ad-about-advertiser-text') }}
            </p>
            <p>{{ $t('retailmedia.ad-advertiser-name-text') }} {{ retailMediaInfo?.advertiserName?.value }}</p>
            <p>{{ $t('retailmedia.ad-advertiser-who-paid-text') }} {{ retailMediaInfo?.paidForBy?.value }}</p>
          </template>
        </Modal>
      </Teleport>
    </template>
  </div>
</template>

<script>
  import { InfoIcon } from 'CommonComponents/UI/Atoms/Icons';
  import { useI18n } from 'vue-i18n';
  import Modal from 'CommonComponents/Modal/Modal.vue';
  import MODAL_TYPES from 'Utils/modalTypes.js';

  export default {
    name: "RetailMediaPreviewBadge",
    components: {
      InfoIcon,
      Modal
    },
    props: {
      retailMediaInfo:{
        type: [Object, undefined],
        default: undefined
      }
    },
    setup() {
      const { t } = useI18n();
      const adSponsoredText = t('retailmedia.ad-sponsored-text');
      
      const showPromotionalModal = ref(false);
      const modalStyle = {
        [MODAL_TYPES.VUESTORE_SIDE_RIGHT_START]: true,
        'fixed-footer': true,
      };
      const toggleRetailMediaModal = () => {
        showPromotionalModal.value = !showPromotionalModal.value;
      };

      const closeModal = () => {
        document.body.style.overflowY = 'auto';
        showPromotionalModal.value = false;
      };

      return {
        adSponsoredText,
        toggleRetailMediaModal,
        modalStyle,
        showPromotionalModal,
        closeModal
      }
    }
  }
</script>

<style>
  .retail-media-promotion-badge {
    display: flex;
    align-items: end;
    font-weight:300;
    font-size: .6875rem;
    margin-bottom: 0.3em;
    line-height: 1em;
    color:#999;
    cursor: pointer;
    border: none;
    background-color: transparent;
    svg {
      margin-left: .25em;
      path {
        fill:#018639
      }
    }
  }
  .retail_media_container__modal_body{
    h3, p{
        margin-bottom:1em;
    }
    p{
        margin:1em 0;
        font-size:0.8rem;
    }
    ul{
        margin-left:2em;
    }
    li{
        font-size:0.8rem;
        &:before{
            content:"·";
            margin-right:0.8em;
            margin-left:-1em;
        }
    }
    .bold{
        font-weight:bold;
    }
  }
</style>