<template>
  <PreviewColorsComplex
    :productUri="productUri"
    :isPdp="isPdp"
    :colorsCollection="colorsCollection"
    @updateColor="updateColor"
    @sendColorName="sendColorName"
    @loadSizes="loadSizes"
  />
</template>

<script>
import PreviewColorsComplex from 'CommonComponents/Product/Selector/Color/PreviewColorsComplex.vue';

export default {
  name: 'PreviewColorsDesktop',
  props: {
    colorsCollection: {
      type: Array,
      required: true,
    },
    isPdp: {
      type: Boolean,
      required: true,
    },
    productUri: {
      type: String,
      default: '',
    },
  },
  emits: ['updateColor', 'sendColorName', 'loadSizes'],
  setup(props, { emit }) {
    const updateColor = (color) => {
      emit('updateColor', color);
    };
    const sendColorName = (colorName) => {
      emit('sendColorName', colorName);
    };
    const loadSizes = () => {
      emit('loadSizes');

    };
    return {
      updateColor,
      sendColorName,
      loadSizes
    };
  },
  components: {
    PreviewColorsComplex,
  },
};
</script>

