<template>
  <div class="modal-actions-desktop-overlay">
    <div
      class="modal-actions-desktop-overlay-image pointer"
      @click="goToPDP"></div>
    <transition
      name="slide"
      @after-enter="checkOverflow"
      @after-leave="resetShowMore">
      <div
        v-if="isOpen"
        ref="selectorContainer"
        role="dialog"
        :aria-label="ariaContainerLabel"
        class="modal-actions-desktop-open"
        :class="{ 'modal-actions-desktop-open--expanded': showMore }">
        <div
          v-if="isComingSoon"
          class="modal-actions-desktop__coming-soon">
          {{ $t('product.choose_variant_coming_soon') }}
        </div>
        <TitleSelector
          v-if="typeOfVariant"
          :title="typeOfVariant"
          class="modal-actions-desktop-title" />
        <slot :isOverflowed="isOverflowing"></slot>
        <div
          v-if="isSizesLoaded"
          class="modal-actions-desktop-show-more-and-size-guide">
          <div
            v-if="isOverflowing && !showMore"
            class="modal-actions-desktop-show-more">
            <button
              class="modal-actions-desktop-show-more__button"
              @click="toggleShowMore">
              {{ $t('product.show_more_sizes') }}
            </button>
          </div>
          <div
            v-if="sizeGuide"
            class="modal-actions-desktop-size_guide">
            <SizeGuideLink
              :class-name="'product_list-size_guide size_selector-header__size_guide'" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import TitleSelector from '../TitleSelector.vue';
  import SizeGuideLink from 'CommonComponents/SizeGuide/SizeGuideLink.vue';
  import useShowMoreOverflow from 'Composables/useShowMoreOverflow.js';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'SelectorProductModalDesktop',
    components: {
      TitleSelector,
      SizeGuideLink,
    },
    props: {
      productUri: {
        type: String,
        required: true,
      },
      showGuidedSizes: {
        type: Boolean,
        default: false,
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
      typeOfVariant: {
        type: String,
        required: false,
        default: '',
      },
      isComingSoon: {
        type: Boolean,
        default: false,
      },
      isSizesLoaded: {
        type: Boolean,
        default: false,
      }
    },
    setup(props) {
      const sizeGuide = inject('sizeGuideUrl');
      const { t } = useI18n();
      const goToPDP = () => {
        location.href = props.productUri;
      };

      // Show More On Overflow
      const selectorContainer = ref(null);
      const { isOverflowing, showMore, checkOverflow, resetShowMore, toggleShowMore } =
        useShowMoreOverflow(selectorContainer);

      const ariaContainerLabel = computed(() => {
        return props.typeOfVariant || t('product_preview.size_selector.title');
      });

      return {
        goToPDP,
        selectorContainer,
        isOverflowing,
        showMore,
        checkOverflow,
        resetShowMore,
        toggleShowMore,
        ariaContainerLabel,
        sizeGuide,
      };
    },
  };
</script>

<style lang="less">
  .slide-enter-active,
  .slide-leave-active {
    transform: translateY(0);
    transition: all 0.2s ease-in;
  }

  .slide-enter-from,
  .slide-leave-to {
    transform: translateY(100%);
  }
</style>
